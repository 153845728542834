<template>
  <base-dialog type="icon" title="Cajas" @open="open">
    <v-row>
      <v-spacer></v-spacer>
      <Add
        @update="
          fetch();
          $emit('update');
        "
      />
    </v-row>
    <base-data-table-cards
      :headers="headers"
      :headers_small="headers"
      :data="items"
      :totalItems="total"
      no_dialog
    >
      <template v-slot:item_name="item">
        {{ item.name }}
        <v-icon v-if="item.default" color="primary">mdi-star</v-icon></template
      >
      <template v-slot:item_actions="item">
        <Edit
          :cash_register="item"
          @update="
            fetch();
            $emit('update');
          "
        />
      </template>
    </base-data-table-cards>
  </base-dialog>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "CashRegisterList",
  data() {
    return {
      headers: [
        { text: "Nombre", value: "name" },
        { text: "Acciones", value: "actions", class: "text-end" },
      ],
      items: null,
      filters: {},
      total: 0,
    };
  },
  components: {
    Add: () => import("@/components/expense/AddCashRegister"),
    Edit: () => import("@/components/expense/EditCashRegister"),
  },
  methods: {
    open() {
      this.fetch();
    },
    ...mapActions("cash_register", ["getCashRegisters"]),
    fetch() {
      this.getCashRegisters({
        pagination: { itemsPerPage: -1 },
        filters: this.filters,
      }).then((result) => {
        this.items = result.data;
        this.total = result.total;
      });
    },
  },
};
</script>
